import 'bootstrap/dist/css/bootstrap.css'

import React from 'react';
import {
    Row, Col,
} from 'reactstrap';

import {Link} from "gatsby";

import '../icofont.min.css';

// Web theme color root css
import '../components/colors.css';

import '../Terms.css';
import Tdsheader from "../components/Tdsheader";

import '../App.css';
import '../responsive.css';
import Tdscontact from "../components/Tdscontact";
import Tdsfooter from "../components/Tdsfooter";
import {Container} from "reactstrap";

export default function Maintenance() {
    return (
        <div className={"App"}>
            {/* Navbar  */}
            <Tdsheader active={"maintenance"} isHome={false} />

            <div className={"section"} id={"maintenance"}>
                <h1 className={"heading mt-5 mb-1 ml-3 ml-sm-5"}>Our Maintenance Program</h1>
                <br/><br/>

                <Container>
                    <h5 className={"text-danger"}>The usual method of periodic testing of jacks to confirm the continuity of performance:</h5>
                    <p>
                        Recommendations of the world's valid standards are mostly used by manufacturing factories in their production lines and because:
                        1) Estimating the ultimate tolerance of the jacks are the basis for recommended test in jack standards and
                        2) Top jack manufacturers have equipped their products with safety valves, therefore, users who only use jacks,
                        cannot expect to apply pressure to the jacks to their nominal capacities (usually showed on the body of the jack),
                        because the safety valves stop the jack before the jack pressure reaches the level of nominal capacity, therefore,
                        the periodic examination method of these types of jacks are based on the level of tolerances which meet the normal needs of users.
                    </p>
                    <br/>

                    <h5 className={"text-danger"}>The set of these examinations is as the following checklist:</h5>
                    <p>
                        <ol>
                            <li>1) Estimating jack lifespan and consequently estimating the need to replace rubber parts</li>
                            <li>2) Checking the appearance form of moving metal parts, joints and jack levers</li>
                            <li>3) Checking the quality and essential volume of the jack hydraulic oil</li>
                            <li>4) Checking possible oil leakage</li>
                            <li>5) Checking the performance without applying pressure</li>
                            <li>6) Checking the performance under pressure</li>
                        </ol>
                        Although these tests do not include jack fixtures, it is essential that users receive the necessary advice on how to correct them and other precautions.
                    </p>
                    <br/>

                    <h5 className={"text-danger"}>Services of Opal Hydraulic company in the field of examination:</h5>

                    <p>
                        Under the agreement, the company's authorized mobile experts are responsible for periodic inspection and examination of manual hydraulic jacks.
                        The experts of this company, while forming a file and assigning a unique code to each of the units under their supervision,
                        after the first visit and based on the nature and volume of the application of each jack, perform the following actions:
                        <br/><br/>
                        <ol>
                            <li>1) Determining the review and control periods of the unit which are determined between three to 12 months, depending on the its condition and volume of consumption.</li>
                            <li>2) Records of inspection and any correction or repair operations are stored in the unit file.</li>
                            <li>3) After the end of each review session, users will receive a written report of the accomplished review via their email.</li>
                            <li>4) If the control checklist is passed, the company label will be installed on the body of the unit which contains the unit code, service date and next inspection deadline.</li>
                            <li>5) If the need for repairs is detected, the defective unit, upon the issued receipt, will be taken delivered and returned to users after repairs.</li>
                            <li>6) If the users declare a need, the company may provide a suitable temporary alternative to users during the repairs of each unit.</li>
                            <li>7) Users of the contracting party enjoy a 30% discount on the rate of necessary repairs.</li>
                            <li>8) The experts of this company provide the necessary technical and safety recommendations to the users in order to extend the lifespan of each unit and improve its safety coefficients.</li>
                            <li>9) The company's message department, provides the necessary notices and coordination for review meetings
                                and possibly performs the necessary coordination to determine the time and place of the visit or the collection and return of the repair units through SMS or e-mail of users.</li>
                            <li className={"text-info"}>10) Users of the contracting party, upon their request, can also enjoy some periodic and extraordinary services of this company,
                                including the replacement of used units with new ones in terms of commercial price and without taking into account the profit of the importer and seller.</li>
                        </ol>
                    </p>
                    <br/>

                    <h4 className={"text-danger"}>Fees</h4>
                    <p>
                        <Row className={"mb-2"}>
                            <Col xs={10} lg={8}>Single session (1-5 units):</Col>
                            <Col xs={2} lg={4}>$100</Col>
                        </Row>
                        <Row className={"mb-2"}>
                            <Col xs={10} lg={8}>First session of annual maintenance (Max 6 units):</Col>
                            <Col xs={2} lg={4}>$100</Col>
                        </Row>
                        <Row className={"mb-2"}>
                            <Col xs={10} lg={8}>Next sessions of support agreement:</Col>
                            <Col xs={2} lg={4}>$70</Col>
                        </Row>
                        <Row className={"mb-2"}>
                            <Col xs={10} lg={8}>In the form of maintenance, monthly fee per each Jack or cylinder:</Col>
                            <Col xs={2} lg={4}>$4.99</Col>
                        </Row>
                        <br/>
                        <strong>Notice 1:</strong> For each unit in addition to the specified number, we receive $10 in excess of the above rates.
                        <br/>
                        <strong>Notice 2:</strong> The detailed rates above are for applicants who are located in the range of 20 kilometers in the position of our active representatives.
                        <br/>
                        <strong>Notice 3:</strong> Maintenance and periodic inspections of car lifters (Hoists), only included their cylinders and monthly fee is calculated per each cylinders.
                    </p>
                    <br/><br/><br/>

                    <h5 className={"text-info"}>What do you need to do?</h5>
                    <p>
                        Before receiving any service from us, you are required to sign an agreement,
                        which includes your acceptance of our necessary terms, conditions and essential warnings.
                        <br/>
                        <Link to={"/agreements#agreement-maintenance"}>Learn about our maintenance contract & agreement.</Link>
                    </p>
                </Container>
            </div>

            {/* Contact Us Section Start  */}
            <div className="section contactus grapping" id="contact">
                <Tdscontact />
            </div>

            {/* Footer  Section Start */}
            <div className="">
                <Tdsfooter/>
            </div>
        </div>
    );
}